/* common components */

.not-found,
.loading-error {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 40px;
}

.app .small-screen {
    display: none;
}

@media screen and (max-width: 750px) {
    .app .big-screen {
        display: none;
    }
    
    .main {
        margin-left: 0 !important;
    }

    .app .small-screen {
        display: block;
        position: fixed;
        padding: 10px 0 0 10px;
    }

    .nav .sider {
        max-width: 256px !important;
        min-width: 256px !important;
    }
}

a:focus {
    text-decoration: none;
}

/* override Ant Design with the following syntax: .yourClass.antdClass */

.error.ant-alert {
    margin-bottom: 20px;
}

.form-button.ant-btn {
    width: 100%;
}

.ant-drawer-body {
    padding: 0;
}

.ant-card {
    border-radius: 4px !important;
}

.ant-modal-mask {
    background-color: rgba(255,245,238, 0.8) !important;
}

a:focus {
    text-decoration: none;
}

/* disable scroll bounce */

html, body {
    height: 100%;
    overflow: hidden;
}

.app {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
}

/* nav and main content layout */

.main {
    margin-left: 200px;
    background: seashell;
    height: 100vh;
}
.nav .sider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    padding-top: 10px;
}

.nav .ant-layout-sider-dark {
    background: #783e22;
}

.nav .sider .ant-menu-dark {
    background: #783e22;
}

.nav .options {
    text-align: center;
    margin: 15px 0 25px 0;
}

.nav .options a {
    color: rgba(255, 255, 255, 0.65)
}

.nav .options a:hover {
    color: white;
}
