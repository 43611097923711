.nav .sider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    padding-top: 10px;
}

.nav .ant-layout-sider-dark {
    background: #783e22;
}

.nav .sider .ant-menu-dark {
    background: #783e22;
}

.nav .options {
    text-align: center;
    margin: 15px 0 25px 0;
}

.nav .options a {
    color: rgba(255, 255, 255, 0.65)
}

.nav .options a:hover {
    color: white;
}